import useTranslation from "next-translate/useTranslation";
import { LanguageUid } from "../../libs/enums";
import styles from "./styles.module.css";

const NoData = () => {
  const { t } = useTranslation("GetLanguageResourceResponse");

  return (
    <div className={styles.container}>
      <h1 className={styles.title} aria-live="polite">
        {t(LanguageUid.NO_DATA)}
      </h1>

      <div className={styles.iconContainer}>
        <i className="fa-solid fa-paperclip" aria-hidden="true"></i>
        <i className="fa-solid fa-newspaper" aria-hidden="true"></i>
        <i className="fa-solid fa-bullhorn" aria-hidden="true"></i>
        <i className="fa-solid fa-note-sticky" aria-hidden="true"></i>
        <i className="fa-solid fa-paper-plane" aria-hidden="true"></i>
        <i
          className={`${styles.face} fa-solid fa-face-rolling-eyes`}
          aria-hidden="true"></i>
      </div>
    </div>
  );
};

export default NoData;
